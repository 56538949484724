export interface MyPpr {
    contactInfo?: string;
    notam?: string;
    allowPublicPpr?: boolean;
    publicPprNumberOfDaysAhead?: number;
    attachments?: MyPprAttachment[];
    productInformations?: MyPprProduct[];
    confirmations?: MyPprConfirmation[];
}

export interface MyPprAttachment {
    id: number;
    displayName: string;
    uri: string;
    type: MyPprAttachmentType;
    sortIndex: number;
    visible: boolean;
}

export interface MyPprProduct {
    id: number;
    text: string;
    price: number;
    sortIndex: number;
}

export interface MyPprConfirmation {
    id: number;
    text: string;
    description: string;
}

export enum MyPprAttachmentType {
    File = 'File'
}
