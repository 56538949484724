import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Stack from '@material-ui/core/Stack';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SchoolIcon from '@material-ui/icons/School';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Club } from '../../models/club';
import FacebookIcon from '@material-ui/icons/Facebook';
import WebIcon from '@material-ui/icons/Web';

const ClubModal = ({ open, handleClose, club }: { open: boolean; handleClose: () => void; club?: Club }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);
    const theme = useTheme();

    if (!club) {
        return <></>;
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="lg">
            <DialogTitle>
                {club.logo && <img src={`/assets/clubs/${club.logo}`} style={{ maxWidth: 32, maxHeight: 32, marginRight: 10 }} />}
                {club.name}
                <Stack direction="row" spacing={theme.spacing(1)} sx={{ marginTop: 1 }}>
                    {club.nlf && <Chip label="Norges Luftsportsforbund" color="primary" />}
                    {club.sections?.map((section) => (
                        <Chip label={section} />
                    ))}
                    {club.school?.map((s) => (
                        <Chip icon={<SchoolIcon />} label={`${t('airport:clubs.school')} ${s}`} color="success" />
                    ))}
                </Stack>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <ReactMarkdown>{club.content}</ReactMarkdown>
            </DialogContent>
            <DialogActions>
                {club.url && (
                    <Button
                        startIcon={<WebIcon />}
                        endIcon={<OpenInNewIcon />}
                        variant="outlined"
                        component="a"
                        href={club.url}
                        target="_blank"
                        rel="noreferrer">
                        {t('airport:clubs.site')}
                    </Button>
                )}
                {club.fb && (
                    <Button
                        startIcon={<FacebookIcon />}
                        endIcon={<OpenInNewIcon />}
                        variant="outlined"
                        component="a"
                        href={club.fb}
                        target="_blank"
                        rel="noreferrer">
                        {t('airport:clubs.fb')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ClubModal;
