import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AirportType } from '../../models/airportType';

const FilterBox = ({
    handleTypeFilterToggle,
    typeFilter,
    availableTags,
    handleFlagFilterToggle,
    tagFilter
}: {
    handleTypeFilterToggle: (value: AirportType) => void;
    typeFilter: string[];
    availableTags: any[];
    handleFlagFilterToggle: (value: string) => void;
    tagFilter: string[];
}): React.ReactElement => {
    const { t } = useTranslation(['common', 'map']);

    return (
        <Card style={{ position: 'absolute', right: 20, top: 100, zIndex: 1000 }}>
            <CardContent sx={{ padding: 1, ':last-child': { paddingBottom: 1 } }}>
                <List dense disablePadding>
                    {Object.values(AirportType)
                        .filter((type) => type !== AirportType.Closed)
                        .map((type) => (
                            <ListItem key={type} disablePadding sx={{ padding: 0 }}>
                                <ListItemButton onClick={() => handleTypeFilterToggle(type)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={typeFilter.indexOf(type) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `filter-type-${type}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={`filter-type-${type}`} primary={t(`common:airportType.${type}`)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    <Divider />
                    {availableTags.map((tag) => (
                        <ListItem key={tag} disablePadding sx={{ padding: 0 }}>
                            <ListItemButton onClick={() => handleFlagFilterToggle(tag)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={tagFilter.indexOf(tag) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `filter-tag-${tag}` }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`filter-tag-${tag}`} primary={tag} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default FilterBox;
