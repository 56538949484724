import { LatLngBounds } from 'leaflet';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const MapBoundsListener = ({ bounds }: { bounds: LatLngBounds }): React.ReactElement => {
    const map = useMap();

    useEffect(() => {
        map.fitBounds(bounds);
    }, [bounds, map]);

    return <></>;
};

export default MapBoundsListener;
