import Alert from '@material-ui/core/Alert';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notam } from '../../models/notam';
import { TimeFromNow } from '../common/TimeFromNow';
import { TimePeriod } from '../common/TimePeriod';

const NotamHrsOfService = ({ notams }: { notams?: Notam[] }): React.ReactElement => {
    const { t } = useTranslation('airport');
    const theme = useTheme();

    const [adHrsOfServiceNotam, setAdHrsOfServiceNotam] = useState<Notam[] | undefined>(undefined);

    useEffect(() => {
        const filtered = notams?.filter((n) => n?.icaoMessage?.includes('AD HR OF SER') || n?.icaoMessage?.includes('AFIS HR OF SER'));
        if ((filtered?.length ?? 0) > 0) {
            setAdHrsOfServiceNotam(filtered);
        } else {
            setAdHrsOfServiceNotam(undefined);
        }
    }, [notams]);

    if (!adHrsOfServiceNotam) {
        return <></>;
    }

    return (
        <Grid item xs={12} md={12}>
            <Alert severity="info">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('notams.openingHours')}
                </Typography>
                {adHrsOfServiceNotam.map((item, index) => (
                    <Typography variant="body2" key={index}>
                        {item.traditionalMessageFrom4thWord.replace('AD HR OF SER: ', '').replace('AFIS HR OF SER: ', '').replace('\n', ' ')}
                        <br />
                        <TimePeriod from={item.startDate} to={item.endDate} isNotam={true} style={{ fontStyle: 'italic', color: theme.palette.grey[700] }} />
                        <br />
                        <TimeFromNow date={item.issueDate} isNotam={true} style={{ fontSize: 12, color: theme.palette.grey[600] }} />
                    </Typography>
                ))}
            </Alert>
        </Grid>
    );
};

export default NotamHrsOfService;
