import Alert from '@material-ui/core/Alert';
import PageContainer from './PageContainer';

const LoadingError = ({ error }: { error: string }): React.ReactElement => {
    return (
        <PageContainer>
            <Alert severity="error">{error}</Alert>
        </PageContainer>
    );
};

export default LoadingError;
