import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TimeFromNow = ({ date, isNotam = false, style = undefined }: { date: string; isNotam: boolean; style: any }): React.ReactElement => {
    const { i18n } = useTranslation();

    const parsedDate = isNotam ? dayjs(date, 'MM/DD/YYYY HHmm') : dayjs(date);

    const dateToNow = parsedDate?.locale(i18n.language).fromNow();

    return <span style={{ ...style }}>{dateToNow}</span>;
};
