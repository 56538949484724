import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency } from '../../models/airport';
import FrequencyIcon from './FrequencyIcon';

const Frequencies = ({ frequencies }: { frequencies?: Frequency[] }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);

    if (!frequencies) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('airport:frequencies.title')} />
                <CardContent>
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>{t('airport:frequencies.station')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:frequencies.frequency')}</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {frequencies.map((frequency: Frequency, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <FrequencyIcon type={frequency.type} /> {frequency.name}
                                        </TableCell>
                                        <TableCell>{frequency.frequency} MHz</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Frequencies;
