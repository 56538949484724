import { AirportOperator } from './airportOperator';
import { AirportType } from './airportType';
import { Club } from './club';
import { Coordinates } from './coordinates';
import { DistanceInfo } from './distanceInfo';
import { FuelProvider } from './fuel';
import { MyPpr } from './myppr';
import { Notam } from './notam';

export interface Airport {
    icao?: string;
    iata: string;
    local?: string;
    gps?: string;
    name: string;
    type: AirportType;
    location: AirportLocationInfo;
    metadata: AirportMetadata;
    fieldElevation: DistanceInfo;
    links: AirportLinks;
    operator?: AirportOperator;
    runways?: Runway[];
    frequencies?: Frequency[];
    navaids?: Navaid[];
    availability?: AirportAvailabilityInfo;
    clubs?: Club[];
    fuel?: FuelProvider[];
    notams?: Notam[];
}

export interface AirportLocationInfo {
    coordinates?: Coordinates;
    region?: string;
    servingArea?: string;
}

export interface AirportMetadata {
    hasScheduledTraffic?: boolean;
}

export interface AirportLinks {
    home?: string;
    wikipedia?: string;
}

export interface Runway {
    length?: DistanceInfo;
    width?: DistanceInfo;
    surface?: RunwaySurface;
    lighted?: boolean;
    closed?: boolean;
    lowerEnd: RunwayPart;
    higherEnd: RunwayPart;
}

export interface RunwayPart {
    ident: string;
    elevation: DistanceInfo;
    heading?: number;
    coordinates?: Coordinates;
    displacedThreshold?: DistanceInfo;
}

export enum RunwaySurface {
    Asphalt = 'Asphalt',
    Concrete = 'Concrete',
    Turf = 'Turf',
    Grass = 'Grass',
    Gravel = 'Gravel',
    GravelGrass = 'Gravel/Grass',
    Water = 'Water',
    Ice = 'Ice',
    Unknown = 'Unknown'
}

export interface Frequency {
    type: FrequencyType;
    name: string;
    frequency: string;
}

export enum FrequencyType {
    Approach = 'Approach',
    Atis = 'ATIS',
    Director = 'Director/Clearance',
    Tower = 'Tower',
    Ground = 'Ground',
    Operations = 'Operations',
    Information = 'Information',
    Traffic = 'Traffic',
    Artc = 'ARTC',
    Pmsv = 'PMSV',
    Misc = 'Misc',
    Unknown = 'Unknown'
}

export interface Navaid {
    ident: string;
    type: NavaidType;
    elevation?: DistanceInfo;
    coordinates?: Coordinates;
    frequency?: number;
    dme: NavaidDme;
    slavedVariation?: number;
    magneticVariation?: number;
    usageType: NavaidUsageType[];
    power: NavaidPowerLevel;
}

export interface NavaidDme {
    frequency?: number;
    channel?: string;
    elevation?: DistanceInfo;
    coordinates?: Coordinates;
}

export enum NavaidType {
    Ndb = 'NDB',
    VorDme = 'VOR-DME',
    Vortac = 'VORTAC',
    Tacan = 'TACAN',
    Unknown = 'Unknown'
}

export enum NavaidUsageType {
    High = 'High',
    Low = 'Low',
    TerminalArea = 'Terminal area',
    Rnav = 'RNAV',
    Unknown = 'Unknown'
}

export enum NavaidPowerLevel {
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
    Unknown = 'Unknown'
}

export interface AirportAvailabilityInfo {
    myPpr?: MyPpr;
    pfly?: Pfly;
    closedOutsideHrs?: boolean;
    ppr?: Ppr;
}

export interface Pfly {
    pflySupported: boolean;
    pprRequired: boolean;
}

export interface Ppr {
    requirement?: PprRequirement;
    url?: string;
}

export enum PprRequirement {
    Always = 'always',
    AfterHrs = 'afterhrs',
    ByNotam = 'notam'
}
