import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Stack from '@material-ui/core/Stack';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, ScaleControl, TileLayer } from 'react-leaflet';
import { useRouteMatch } from 'react-router-dom';
import { Airport } from '../../models/airport';
import useTitle from '../../tools/useTitle';
import { DistanceText } from '../common/DistanceText';
import { InfoBox } from '../common/InfoBox';
import Loading from '../common/Loading';
import LoadingError from '../common/LoadingError';
import PageContainer from '../common/PageContainer';
import { getMarkerIconForType } from '../map/MapIcons';
import AirportLinks from './AirportLinks';
import ClubList from './ClubList';
import Frequencies from './Frequencies';
import FuelList from './FuelList';
import MyPprInfo from './MyPprInfo';
import Navaids from './Navaids';
import NotamHrsOfService from './NotamHrsOfService';
import NotamList from './NotamList';
import PflyModal from './PflyModal';
import Runways from './Runways';
import SunriseSunset from './SunriseSunset';

const AirportDetails = (): React.ReactElement => {
    const [airport, setAirport] = useState<Airport | undefined>(undefined);
    const [errorLoadingAirport, setErrorLoadingAirport] = useState(false);
    const [airportCoordinates, setAirportCoordinates] = useState<LatLngTuple | undefined>(undefined);
    const { t } = useTranslation(['common', 'airport']);
    const theme = useTheme();

    const { params }: { params: { filename: string } } = useRouteMatch();

    const [title, setTitle] = useState('Airport');
    useTitle(title);

    const [pflyDialogOpen, setPflyDialogOpen] = useState(false);

    useEffect(() => {
        fetch(`/airports/${params.filename.toUpperCase()}.json`)
            .then((response) => response.json())
            .then((data) => {
                const airportData = data as Airport;
                if (!airportData) {
                    setErrorLoadingAirport(true);
                    return;
                }
                setAirport(airportData);
                setAirportCoordinates(
                    airportData.location?.coordinates
                        ? ([airportData.location?.coordinates?.latitude, airportData.location?.coordinates?.longitude] as LatLngTuple)
                        : undefined
                );
                setTitle(`${airportData?.name}`);
            })
            .catch(() => setErrorLoadingAirport(true));
    }, [params.filename]);

    if (errorLoadingAirport) {
        return <LoadingError error={t('airport:error')} />;
    }

    if (!airport || !airportCoordinates) {
        return <Loading />;
    }

    return (
        <PageContainer fullSize>
            <Grid container spacing={theme.spacing(2)}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h1">
                        {airport.name}
                    </Typography>
                    <Stack direction="row" spacing={theme.spacing(2)} sx={{ marginTop: 2 }}>
                        {airport.metadata.hasScheduledTraffic && (
                            <Chip icon={<FlightTakeoffIcon />} label={t('airport:general.hasScheduledTraffic')} color="primary" variant="outlined" />
                        )}
                        <Chip label={t(`common:airportType.${airport.type}`)} variant="outlined" />
                        {airport.availability?.pfly && (
                            <Chip
                                clickable
                                onClick={() => setPflyDialogOpen(true)}
                                label="PFLY"
                                color="success"
                                sx={{ borderRadius: { xs: 2 }, fontWeight: 'bold' }}
                            />
                        )}

                        {airport.operator && (
                            <Typography variant="subtitle1" title={t('airport:general.operator')} component="span">
                                {airport.operator?.name}{' '}
                                {airport.operator?.url && (
                                    <IconButton component="a" href={airport.operator.url} target="_blank" rel="noreferrer">
                                        <OpenInNewIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} container direction="column" spacing={theme.spacing(2)}>
                    {airportCoordinates && (
                        <Grid item xs={1}>
                            <MapContainer style={{ height: 400 }} center={airportCoordinates} zoom={14}>
                                <TileLayer
                                    attribution='<a href="https://www.kartverket.no/">Kartverket</a>'
                                    url="https://opencache{s}.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}"
                                    subdomains={['', '2', '3']}
                                    minZoom={0}
                                    maxZoom={20}
                                    detectRetina={true}
                                />
                                <ScaleControl />
                                <Marker key={airport.icao} position={airportCoordinates} icon={getMarkerIconForType(airport.type)} />
                            </MapContainer>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Grid container spacing={theme.spacing(1)}>
                            <NotamHrsOfService notams={airport.notams} />
                            <SunriseSunset coordinates={airport.location.coordinates} />
                            <ClubList clubs={airport.clubs} />
                            <AirportLinks airport={airport} />
                            <InfoBox label={t('airport:general.icaoCode')}>{airport.icao}</InfoBox>
                            <InfoBox label={t('airport:general.iataCode')}>{airport.iata}</InfoBox>
                            <InfoBox label={t('airport:general.localCode')}>{airport.local}</InfoBox>
                            <InfoBox label={t('airport:general.gpsCode')}>{airport.gps}</InfoBox>
                            {airport.fieldElevation && (
                                <InfoBox label={t('airport:general.fieldElevation')} padding={2.75}>
                                    <DistanceText distance={airport.fieldElevation} />
                                </InfoBox>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={theme.spacing(2)}>
                        <MyPprInfo icao={airport.icao} availability={airport.availability} />

                        <NotamList notams={airport.notams} />

                        <FuelList fuelProviders={airport.fuel} />

                        <Runways runways={airport.runways} />

                        <Frequencies frequencies={airport.frequencies} />

                        <Navaids navaids={airport.navaids} />
                    </Grid>
                </Grid>
            </Grid>
            <PflyModal open={pflyDialogOpen} handleClose={() => setPflyDialogOpen(false)} pprRequired={!!airport.availability?.pfly?.pprRequired} />
        </PageContainer>
    );
};

export default AirportDetails;
