import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DarkModeIcon from '@material-ui/icons/DarkMode';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as SunCalc from 'suncalc';
import { Coordinates } from '../../models/coordinates';

const isValidDate = (d: Date) => {
    // @ts-ignore
    return d instanceof Date && !isNaN(d);
};

const SunriseSunset = ({ coordinates }: { coordinates?: Coordinates }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);
    const theme = useTheme();

    const solarTimes = useMemo(() => SunCalc.getTimes(new Date(), coordinates?.latitude ?? 0, coordinates?.longitude ?? 0), [coordinates]);

    if (!coordinates) {
        return <></>;
    }

    if (!isValidDate(solarTimes.sunrise) && !isValidDate(solarTimes.sunset)) {
        const positionAtSolarNoon = SunCalc.getPosition(solarTimes.solarNoon, coordinates.latitude, coordinates.longitude);
        if (positionAtSolarNoon.altitude > 0) {
            return (
                <Grid item xs={12} md={12}>
                    <Paper sx={{ padding: 5, textAlign: 'center' }}>
                        <WbSunnyIcon color="success" />
                        <Typography textAlign="center" sx={{ color: theme.palette.success.main }}>
                            {t('airport:sunrise.midnightSun')}
                        </Typography>
                    </Paper>
                </Grid>
            );
        } else {
            return (
                <Grid item xs={12} md={12}>
                    <Paper sx={{ padding: 5, textAlign: 'center' }}>
                        <DarkModeIcon color="error" />
                        <Typography textAlign="center" sx={{ color: theme.palette.warning.main }}>
                            {t('airport:sunrise.polarNight')}
                        </Typography>
                    </Paper>
                </Grid>
            );
        }
    }

    return (
        <Grid item xs={12} md={12}>
            <Paper sx={{ padding: 5 }}>
                <Typography textAlign="center" color="textSecondary">
                    <WbSunnyIcon fontSize="small" /> {t('airport:sunrise.dayStarts')}: {solarTimes.dawn.toLocaleTimeString()} ({t('airport:sunrise.sunUp')}:{' '}
                    {solarTimes.sunrise.toLocaleTimeString()})
                    <br />
                    <DarkModeIcon fontSize="small" /> {t('airport:sunrise.nightStarts')}: {solarTimes.dusk.toLocaleTimeString()} ({t('airport:sunrise.sunDown')}
                    : {solarTimes.sunset.toLocaleTimeString()})
                </Typography>
            </Paper>
        </Grid>
    );
};

export default SunriseSunset;
