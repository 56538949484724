import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LatLngTuple } from 'leaflet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, Popup } from 'react-leaflet';
import { Link as RouterLink } from 'react-router-dom';
import { AirportInfo } from '../../models/airports';
import useTitle from '../../tools/useTitle';
import { getMarkerIconForType } from './MapIcons';

const AirportMarker = ({ airport }: { airport: AirportInfo }): React.ReactElement => {
    const { t } = useTranslation(['common', 'map']);

    useTitle(t('map:title'));

    return (
        <Marker key={airport.icao} position={[airport.latitude, airport.longitude] as LatLngTuple} icon={getMarkerIconForType(airport.type)}>
            <Popup maxWidth={800}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">{airport.icao}</Typography>
                    <Typography variant="subtitle1">{airport.name}</Typography>
                    <Container sx={{ marginBottom: 2 }}>
                        <Chip label={t(`common:airportType.${airport.type}`)} variant="outlined" />
                        <br />
                        {airport?.tags?.map((tag) => (
                            <Chip key={tag} label={tag} sx={{ marginTop: 1, marginRight: 1, marginLeft: 1, padding: 0, borderRadius: { xs: 2 } }} />
                        ))}
                    </Container>

                    <Button variant="outlined" component={RouterLink} to={`/airport/${airport.icao}`}>
                        {t('map:popup.details')}
                    </Button>
                </Box>
            </Popup>
        </Marker>
    );
};

export default AirportMarker;
