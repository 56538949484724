import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchResults from './SearchResults';

const SearchBar = (): React.ReactElement => {
    const { t } = useTranslation();

    const [query, setQuery] = useState('');

    return (
        <>
            <Box component="form" noValidate autoComplete="off" mt={5} mb={5} onSubmit={(e: any): void => e.preventDefault()}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    label={t('search.title')}
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.target?.value ?? '')}
                />
            </Box>
            <SearchResults query={query} />
        </>
    );
};

export default SearchBar;
