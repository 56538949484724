import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import Stack from '@material-ui/core/Stack';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import airports from '../../data/airports.json';
import { AirportType } from '../../models/airportType';
import useTitle from '../../tools/useTitle';
import PageContainer from '../common/PageContainer';
import SearchBar from '../search/SearchBar';

const Home = (): React.ReactElement => {
    const { t } = useTranslation(['common', 'home']);
    const theme = useTheme();

    useTitle(t('home:title'));

    return (
        <PageContainer>
            <Typography variant="h3" component="h1">
                {t('home:title')}
            </Typography>
            <Hidden mdDown>
                <Stack direction="row" spacing={theme.spacing(4)} justifyContent="space-evenly" mt={4}>
                    <Badge badgeContent={airports.airports.filter((a) => a.type === AirportType.Small).length} color="primary">
                        <Chip variant="outlined" label={t('common:airportType.small')} sx={{ borderRadius: { xs: 1 } }} />
                    </Badge>
                    <Badge badgeContent={airports.airports.filter((a) => a.type === AirportType.Medium).length} color="primary">
                        <Chip variant="outlined" label={t('common:airportType.medium')} sx={{ borderRadius: { xs: 1 } }} />
                    </Badge>
                    <Badge badgeContent={airports.airports.filter((a) => a.type === AirportType.Large).length} color="primary">
                        <Chip variant="outlined" label={t('common:airportType.large')} sx={{ borderRadius: { xs: 1 } }} />
                    </Badge>
                    <Badge badgeContent={airports.airports.filter((a) => a.type === AirportType.SeaplaneBase).length} color="primary">
                        <Chip variant="outlined" label={t('common:airportType.seaplane')} sx={{ borderRadius: { xs: 1 } }} />
                    </Badge>
                    <Badge badgeContent={airports.airports.filter((a) => a.type === AirportType.Heliport).length} color="primary">
                        <Chip variant="outlined" label={t('common:airportType.heliport')} sx={{ borderRadius: { xs: 1 } }} />
                    </Badge>
                </Stack>
            </Hidden>
            <SearchBar />
        </PageContainer>
    );
};

export default Home;
