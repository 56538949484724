import { Icon } from 'leaflet';
import { AirportType } from '../../models/airportType';

const markerIconAirport = new Icon({
    iconUrl: '/assets/airport.svg',
    iconSize: [24, 24]
});

const markerIconHeliport = new Icon({
    iconUrl: '/assets/heliport.svg',
    iconSize: [24, 24]
});

const markerIconSeaplane = new Icon({
    iconUrl: '/assets/seaplane.svg',
    iconSize: [24, 24]
});

export const getMarkerIconForType = (type: AirportType): Icon => {
    switch (type) {
        case AirportType.Small:
        case AirportType.Medium:
        case AirportType.Large:
        case AirportType.Closed:
            return markerIconAirport;
        case AirportType.Heliport:
            return markerIconHeliport;
        case AirportType.SeaplaneBase:
            return markerIconSeaplane;
        default:
            return markerIconAirport;
    }
};
