import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navaid } from '../../models/airport';
import { textToMorse } from '../../tools/textToMorse';
import NavaidIcon from './NavaidIcon';
const Navaids = ({ navaids }: { navaids?: Navaid[] }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);

    if (!navaids) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('airport:navaids.title')} />
                <CardContent>
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>{t('airport:navaids.type')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:navaids.frequency')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:navaids.magneticVariation')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:navaids.ident')}</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {navaids.map((navaid: Navaid, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <NavaidIcon type={navaid.type} /> {navaid.type}
                                        </TableCell>
                                        <TableCell>
                                            {navaid.frequency ? (navaid.frequency > 1000 ? `${navaid.frequency / 1000} MHz` : `${navaid.frequency} kHz`) : ''}
                                            {navaid.dme?.channel && (
                                                <>
                                                    <br />
                                                    CH{navaid.dme.channel}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>{navaid.magneticVariation && <>{navaid.magneticVariation}</>}</TableCell>
                                        <TableCell>
                                            {navaid.ident}{' '}
                                            {navaid.ident && (
                                                <span>
                                                    <br />
                                                    {textToMorse(navaid.ident)}
                                                </span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Navaids;
