import Alert from '@material-ui/core/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FuelInfo, FuelProvider } from '../../models/fuel';

const FuelList = ({ fuelProviders }: { fuelProviders?: FuelProvider[] }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);

    if (!fuelProviders || fuelProviders.length === 0) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('airport:fuel.title')} />
                <CardContent>
                    <TableContainer component={Box}>
                        <Table>
                            <TableBody>
                                {fuelProviders.map((fuelProvider: FuelProvider, index: number) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <Link href={fuelProvider.provider.url} target="_blank" rel="noreferrer">
                                                {fuelProvider.provider.name} <OpenInNewIcon fontSize="small" />
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {fuelProvider.fuels.map((fuel: FuelInfo, fuelIndex: number) => (
                                                        <TableRow key={fuelIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell>
                                                                {fuel.type}{' '}
                                                                {fuel.url && (
                                                                    <Link href={fuel.url} target="_blank" rel="noreferrer">
                                                                        <OpenInNewIcon fontSize="small" />
                                                                    </Link>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{fuel.info && <Alert severity="info">{fuel.info}</Alert>}</TableCell>
                                                            <TableCell align="right">
                                                                {fuel.selfService && (
                                                                    <Chip
                                                                        icon={<LocalGasStationIcon />}
                                                                        label={t('airport:fuel.selfService')}
                                                                        color="success"
                                                                    />
                                                                )}
                                                                {fuel.fuelTruck && (
                                                                    <Chip icon={<LocalShippingIcon />} label={t('airport:fuel.fuelTruck')} color="warning" />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default FuelList;
