import { LatLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useMemo, useState } from 'react';
import { MapContainer, ScaleControl, TileLayer } from 'react-leaflet';
import airportData from '../../data/airports.json';
import { AirportInfo } from '../../models/airports';
import { AirportType } from '../../models/airportType';
import AirportMarker from './AirportMarker';
import FilterBox from './FilterBox';
import MapBoundsListener from './MapBoundsListener';

const AirportMap = (): React.ReactElement => {
    const availableTags = [...new Set(airportData.airports.flatMap((airport) => airport.tags))];

    const [typeFilter, setTypeFilter] = useState<string[]>([AirportType.Small, AirportType.Medium, AirportType.Large]);
    const [tagFilter, setTagFilter] = useState<string[]>([]);

    const airports = useMemo<AirportInfo[]>(
        () =>
            (tagFilter.length === 0
                ? airportData.airports.filter((airport) => typeFilter.includes(airport.type))
                : airportData.airports
                      .filter((airport) => typeFilter.includes(airport.type))
                      .filter((airport) => tagFilter.every((tag) => airport.tags?.some((at) => at === tag)))) as AirportInfo[],
        [tagFilter, typeFilter]
    );

    const boundingBox = useMemo(() => {
        const latitudes = airports.filter((airport) => airport.latitude).flatMap((airport) => airport.latitude ?? 0);
        const longitudes = airports.filter((airport) => airport.longitude).flatMap((airport) => airport.longitude ?? 0);

        if (!latitudes || latitudes.length === 0 || !longitudes || longitudes.length === 0) {
            return new LatLngBounds([
                [59, 5],
                [59, 9]
            ]);
        }

        return new LatLngBounds([
            [Math.min.apply(null, latitudes), Math.min.apply(null, longitudes)],
            [Math.max.apply(null, latitudes), Math.max.apply(null, longitudes)]
        ]);
    }, [airports]);

    const handleTypeFilterToggle = (value: AirportType): void => {
        const currentIndex = typeFilter.indexOf(value);
        const newTypeFilter = [...typeFilter];

        if (currentIndex === -1) {
            newTypeFilter.push(value);
        } else {
            newTypeFilter.splice(currentIndex, 1);
        }

        setTypeFilter(newTypeFilter);
    };

    const handleFlagFilterToggle = (value: string): void => {
        const currentIndex = tagFilter.indexOf(value);
        const newTagFilter = [...tagFilter];

        if (currentIndex === -1) {
            newTagFilter.push(value);
        } else {
            newTagFilter.splice(currentIndex, 1);
        }

        setTagFilter(newTagFilter);
    };

    return (
        <>
            <FilterBox
                handleTypeFilterToggle={handleTypeFilterToggle}
                typeFilter={typeFilter}
                availableTags={availableTags}
                handleFlagFilterToggle={handleFlagFilterToggle}
                tagFilter={tagFilter}
            />
            <MapContainer scrollWheelZoom={true} style={{ minHeight: '100%', minWidth: '100%' }} bounds={boundingBox}>
                <TileLayer
                    attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>,
                <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
                <ScaleControl />
                <MapBoundsListener bounds={boundingBox} />
                {airports.map((airport) => (
                    <AirportMarker key={airport.icao} airport={airport} />
                ))}
            </MapContainer>
        </>
    );
};

export default AirportMap;
