import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

const Header = ({ onHeightUpdate }: { onHeightUpdate: (height: number) => void }): React.ReactElement => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        onHeightUpdate(height);
    }, [height, onHeightUpdate]);

    return (
        <AppBar position="fixed" ref={ref}>
            <Toolbar>
                <Button color="inherit" aria-label="home" sx={{ mr: 2 }} onClick={() => history.push('/')}>
                    Airports
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton edge="start" color="inherit" aria-label="home" sx={{ mr: 2 }} onClick={() => history.push('/')}>
                    <HomeIcon />
                </IconButton>
                <IconButton edge="start" color="inherit" aria-label="map" sx={{ mr: 2 }} onClick={() => history.push('/map')}>
                    <MapIcon />
                </IconButton>
                <IconButton edge="start" color="inherit" aria-label="about" sx={{ mr: 2 }} onClick={() => history.push('/about')}>
                    <InfoIcon />
                </IconButton>
                {i18n.language !== 'nb' && (
                    <IconButton edge="end" color="inherit" aria-label="change language to Norwegian" sx={{ mr: 2 }} onClick={() => i18n.changeLanguage('nb')}>
                        <img src="/assets/en.png" />
                    </IconButton>
                )}
                {i18n.language === 'nb' && (
                    <IconButton edge="end" color="inherit" aria-label="change language to English" sx={{ mr: 2 }} onClick={() => i18n.changeLanguage('en')}>
                        <img src="/assets/no.png" />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
