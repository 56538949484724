import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export const InfoBox = ({
    children,
    label,
    padding = 5,
    infoFontSize = 24
}: {
    children?: string | React.ReactElement | React.ReactElement[];
    label: string;
    padding?: number | any;
    infoFontSize?: number;
}): React.ReactElement => {
    if (!children) {
        return <></>;
    }

    return (
        <Grid item xs={6} md={6}>
            <Paper sx={{ padding }}>
                <Typography sx={{ fontSize: infoFontSize }} textAlign="center">
                    {children}
                </Typography>
                <Typography textAlign="center" color="textSecondary">
                    {label}
                </Typography>
            </Paper>
        </Grid>
    );
};
