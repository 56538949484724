import { FrequencyType } from '../../models/airport';
import RadarIcon from '@material-ui/icons/Radar';
import InfoIcon from '@material-ui/icons/Info';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PersonIcon from '@material-ui/icons/Person';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

const FrequencyIcon = ({ type }: { type: FrequencyType }): React.ReactElement => {
    switch (type) {
        case FrequencyType.Approach:
        case FrequencyType.Artc:
            return <RadarIcon fontSize="inherit" />;
        case FrequencyType.Atis:
            return <InfoIcon fontSize="inherit" />;
        case FrequencyType.Traffic:
        case FrequencyType.Information:
            return <HeadsetMicIcon fontSize="inherit" />;
        case FrequencyType.Tower:
            return <PersonIcon fontSize="inherit" />;
        default:
            return <SettingsInputAntennaIcon fontSize="inherit" />;
    }
};

export default FrequencyIcon;
