import React from 'react';
import { Switch } from 'react-router-dom';
import About from './about/About';
import { RouteWithExceptionBoundary as Route } from './common/RouteWithExceptionBoundary';
import AirportDetails from './details/AirportDetails';
import Home from './home/Home';
import AirportMap from './map/AirportMap';

const Routes = (): React.ReactElement => {
    return (
        <Switch>
            <Route path="/map" exact>
                <AirportMap />
            </Route>
            <Route path="/airport/:filename">
                <AirportDetails />
            </Route>
            <Route path="/about" exact>
                <About />
            </Route>
            <Route path={['/']}>
                <Home />
            </Route>
        </Switch>
    );
};

export default Routes;
