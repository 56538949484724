import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Club } from '../../models/club';
import ClubModal from './ClubModal';

const ClubList = ({ clubs }: { clubs?: Club[] }): React.ReactElement => {
    const { t } = useTranslation('airport');
    const theme = useTheme();

    const [clubDialogOpen, setClubDialogOpen] = useState(false);
    const [selectedClub, setSelectedClub] = useState<Club | undefined>(undefined);

    const showClub = (club: Club): void => {
        setSelectedClub(club);
        setClubDialogOpen(true);
    };

    if (!clubs || clubs.length === 0) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('clubs.title')} />
                <CardContent>
                    <Grid container>
                        {clubs.map((club: Club, index: number) => (
                            <Grid
                                item
                                xs
                                key={`${index}`}
                                sx={{ margin: 1, padding: 1, cursor: 'pointer', ':hover': { backgroundColor: theme.palette.action.hover } }}
                                onClick={() => showClub(club)}
                                textAlign="center">
                                <Link variant="h5" component="span" underline="none">
                                    {club.logo && (
                                        <div>
                                            <img src={`/assets/clubs/${club.logo}`} style={{ maxWidth: 100, maxHeight: 100 }} />
                                        </div>
                                    )}
                                    {club.name}
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
            <ClubModal open={clubDialogOpen} handleClose={() => setClubDialogOpen(false)} club={selectedClub} />
        </Grid>
    );
};

export default ClubList;
