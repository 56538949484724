import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GitHubIcon from '@material-ui/icons/GitHub';
import dayjs from 'dayjs';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../../package.json';
import airports from '../data/airports.json';

const Footer = (): React.ReactElement => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const generatedDate = dayjs(airports.generated);

    return (
        <footer style={{ flexShrink: 0, padding: theme.spacing(2, 4), backgroundColor: theme.palette.grey[300] }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Hidden smDown>
                    <Grid item xs>
                        <Typography variant="subtitle2" align="left" color="textSecondary" component="p">
                            {t('footer.generated', {
                                date: generatedDate.locale(i18n.language).format(i18n.language === 'nb' ? 'Do MMMM YYYY' : 'MMMM Do, YYYY'),
                                ago: generatedDate.locale(i18n.language).fromNow()
                            })}
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid item xs>
                    <Typography variant="subtitle2" align="right" color="textSecondary" component="p">
                        {t('footer.version', { version: packageJson.version })}{' '}
                        <IconButton component="a" href="https://github.com/thomfre/airports">
                            <GitHubIcon />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
