import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HighlightIcon from '@material-ui/icons/Highlight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Runway } from '../../models/airport';
import { DistanceText } from '../common/DistanceText';

const Runways = ({ runways }: { runways?: Runway[] }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);

    if (!runways) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('airport:runways.title')} />
                <CardContent>
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>{t('airport:runways.runway')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:runways.length')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:runways.width')}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('airport:runways.surface')}</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {runways.map((runway: Runway, index: number) => (
                                    <React.Fragment key={`${index}`}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {runway.lowerEnd.ident}
                                            </TableCell>
                                            <TableCell>
                                                <DistanceText distance={runway.length} />
                                            </TableCell>
                                            <TableCell>
                                                <DistanceText distance={runway.width} />
                                            </TableCell>
                                            <TableCell>
                                                {t(`common:runwaySurface.${runway.surface ?? 'Unknown'}`)}
                                                {!!runway.lighted && <HighlightIcon sx={{ float: 'right' }} />}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {runway.higherEnd.ident}
                                            </TableCell>
                                            <TableCell>
                                                <DistanceText distance={runway.length} />
                                            </TableCell>
                                            <TableCell>
                                                <DistanceText distance={runway.width} />
                                            </TableCell>
                                            <TableCell>
                                                {t(`common:runwaySurface.${runway.surface ?? 'Unknown'}`)}
                                                {!!runway.lighted && <HighlightIcon sx={{ float: 'right' }} />}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Runways;
