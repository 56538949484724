import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Loading from './components/common/Loading';
import Layout from './components/Layout';
import './language/i18n';
require('dayjs/locale/nb');
require('dayjs/locale/en');

const configureDayJs = (): void => {
    dayjs.extend(relativeTime);
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(utc);
};

const App = (): React.ReactElement => {
    configureDayJs();

    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Layout />
            </Router>
        </Suspense>
    );
};

export default App;
