import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

const Loading = (): React.ReactElement => {
    const theme = useTheme();

    return (
        <Backdrop sx={{ color: theme.palette.info.light, zIndex: theme.zIndex.appBar + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loading;
