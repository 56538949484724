import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemButton from '@material-ui/core/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AirportAvailabilityInfo } from '../../models/airport';
import { MyPprAttachment, MyPprAttachmentType, MyPprProduct } from '../../models/myppr';
import RenderHtml from '../common/RenderHtml';

const MyPprInfo = ({ icao, availability }: { icao?: string; availability?: AirportAvailabilityInfo }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);
    const theme = useTheme();

    const [filesExpanded, setFilesExpanded] = useState(true);
    const [productsExpanded, setProductsExpanded] = useState(false);

    if (!icao || !availability || !availability.myPpr) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    title={<img src="/assets/logo/myppr.png" />}
                    subheader={t('airport:myPpr.title')}
                    action={
                        <Button
                            endIcon={<OpenInNewIcon />}
                            component="a"
                            href={`https://www.myppr.no/aerodrome/${icao.toLowerCase()}`}
                            target="_blank"
                            rel="noreferrer"
                            variant="contained"
                            color="primary"
                            sx={{ marginLeft: 2 }}>
                            {t('airport:myPpr.apply')}
                        </Button>
                    }
                    sx={{ paddingBottom: 0 }}
                />
                <CardContent>
                    <Grid container spacing={theme.spacing(2)}>
                        <Grid item xs={12} md={12} lg={7}>
                            {availability.myPpr.notam && (
                                <Alert severity="info">
                                    <RenderHtml html={availability.myPpr.notam} />
                                </Alert>
                            )}
                            {availability.myPpr.contactInfo && (
                                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                                    <CardHeader avatar={<ContactPhoneIcon />} subheader={<RenderHtml html={availability.myPpr.contactInfo} />} />
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <Typography variant="h5" component="h3">
                                {t('airport:myPpr.files.header')}
                                <IconButton onClick={() => setFilesExpanded(!filesExpanded)}>
                                    <ExpandMoreIcon sx={{ transform: !filesExpanded ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                                </IconButton>
                            </Typography>
                            <Collapse in={filesExpanded} timeout="auto" unmountOnExit>
                                <>
                                    {(!availability.myPpr.attachments || availability.myPpr.attachments.length === 0) && (
                                        <i>{t('airport:myPpr.files.empty')}</i>
                                    )}
                                    {availability.myPpr.attachments && availability.myPpr.attachments.length > 0 && (
                                        <List>
                                            {availability.myPpr.attachments
                                                .sort((a, b) => a.sortIndex - b.sortIndex)
                                                .filter((attachment: MyPprAttachment) => attachment.visible)
                                                .map((attachment: MyPprAttachment) => {
                                                    switch (attachment.type) {
                                                        case MyPprAttachmentType.File:
                                                            return (
                                                                <ListItem key={attachment.id}>
                                                                    <ListItemButton
                                                                        component="a"
                                                                        href={`https://www.myppr.no/${attachment.uri}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        sx={{ padding: 0 }}>
                                                                        <ListItemAvatar>
                                                                            <DescriptionIcon />
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary={attachment.displayName} />
                                                                        <ListItemIcon>
                                                                            <OpenInNewIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            );
                                                        default:
                                                            return <></>;
                                                    }
                                                })}
                                        </List>
                                    )}
                                </>
                            </Collapse>
                            {availability.myPpr.productInformations && availability.myPpr.productInformations.length > 0 && (
                                <>
                                    <Typography variant="h5" component="h3">
                                        {t('airport:myPpr.products.header')}
                                        <IconButton onClick={() => setProductsExpanded(!productsExpanded)}>
                                            <ExpandMoreIcon sx={{ transform: !productsExpanded ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                                        </IconButton>
                                    </Typography>
                                    <Collapse in={productsExpanded} timeout="auto" unmountOnExit>
                                        <List>
                                            {availability.myPpr.productInformations
                                                .sort((a, b) => a.sortIndex - b.sortIndex)
                                                .map((product: MyPprProduct) => (
                                                    <ListItem key={product.id} divider>
                                                        <ListItemAvatar>
                                                            <ShoppingCartIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={product.text} />
                                                        {product.price > 0 && <Typography>kr {product.price},- </Typography>}
                                                    </ListItem>
                                                ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default MyPprInfo;
