import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notam } from '../../models/notam';
import { TimeFromNow } from '../common/TimeFromNow';
import { TimePeriod } from '../common/TimePeriod';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

const NotamList = ({ notams }: { notams?: Notam[] }): React.ReactElement => {
    const { t } = useTranslation('airport');
    const theme = useTheme();

    const [notamsExpanded, setNotamsExpanded] = useState(false);

    if (!notams || notams.length === 0) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    title={t('notams.title')}
                    subheader={t('notams.warning')}
                    action={
                        <IconButton onClick={() => setNotamsExpanded(!notamsExpanded)}>
                            <ExpandMoreIcon sx={{ transform: !notamsExpanded ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                        </IconButton>
                    }
                />
                <Collapse in={notamsExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>{t('notams.number')}</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>{t('notams.message')}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notams.map((notam: Notam, index: number) => (
                                        <TableRow key={`${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {notam.notamNumber}
                                            </TableCell>
                                            <TableCell>
                                                <pre>{notam.icaoMessage}</pre>
                                                <TimePeriod
                                                    from={notam.startDate}
                                                    to={notam.endDate}
                                                    isNotam={true}
                                                    style={{ fontStyle: 'italic', color: theme.palette.grey[700] }}
                                                />
                                                <br />
                                                <TimeFromNow date={notam.issueDate} isNotam={true} style={{ fontSize: 12, color: theme.palette.grey[600] }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
};

export default NotamList;
