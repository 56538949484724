import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import information from '../../data/information.json';

const PflyModal = ({ open, handleClose, pprRequired }: { open: boolean; handleClose: () => void; pprRequired: boolean }): React.ReactElement => {
    const { t } = useTranslation(['common', 'airport']);
    const theme = useTheme();

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle>
                PFLY
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {pprRequired && (
                    <Alert severity="info" sx={{ marginBottom: 2 }}>
                        {t('airport:pfly.pprRequired')}
                    </Alert>
                )}
                <Typography variant="body1">{t('airport:pfly.info')}</Typography>
                <ul>
                    {information.pfly.links.map((l, i) => (
                        <li key={i}>
                            <Link href={l.url} target="_blank" rel="noreferrer">
                                {l.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    {t('common:dialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PflyModal;
