import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Airport } from '../../models/airport';

const AirportLink = ({ url, title }: { url: string; title: string }): React.ReactElement => {
    return (
        <li>
            <Link href={url} target="_blank" rel="noreferrer">
                {title}
            </Link>
        </li>
    );
};

const AirportLinks = ({ airport }: { airport: Airport }): React.ReactElement => {
    const { t } = useTranslation('airport');

    return (
        <Grid item xs={12} md={12}>
            <Paper sx={{ padding: 2 }}>
                <ul>
                    {airport.links.home && <AirportLink url={airport.links.home} title={t('links.official')} />}
                    {airport.links.wikipedia && <AirportLink url={airport.links.wikipedia} title={t('links.wikipedia')} />}
                    {airport.location?.coordinates && (
                        <>
                            <AirportLink
                                url={`https://www.google.com/maps/search/?api=1&query=${airport.location.coordinates.latitude},${airport.location.coordinates.longitude}`}
                                title={t('links.googleMaps')}
                            />
                            <AirportLink
                                url={`https://earth.google.com/web/@${airport.location.coordinates.latitude},${airport.location.coordinates.longitude},22.77097792a,61422.54591253d`}
                                title={t('links.googleEarth')}
                            />
                        </>
                    )}
                    <AirportLink url={`https://ourairports.com/airports/${airport.icao}/`} title={t('links.ourAirports')} />
                </ul>
            </Paper>
        </Grid>
    );
};

export default AirportLinks;
