import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import about from '../locales/en/about.json';
import airport from '../locales/en/airport.json';
import common from '../locales/en/common.json';
import home from '../locales/en/home.json';
import map from '../locales/en/map.json';
import aboutNo from '../locales/nb/about.json';
import airportNo from '../locales/nb/airport.json';
import commonNo from '../locales/nb/common.json';
import homeNo from '../locales/nb/home.json';
import mapNo from '../locales/nb/map.json';

export const defaultNS = 'common';
export const resources = {
    en: {
        common,
        home,
        map,
        airport,
        about
    },
    nb: {
        common: commonNo,
        home: homeNo,
        map: mapNo,
        airport: airportNo,
        about: aboutNo
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        supportedLngs: ['en', 'nb'],
        fallbackLng: 'en',
        defaultNS: defaultNS,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        },
        resources
    });

export default i18n;
