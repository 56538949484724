import lunr from 'lunr';
import airports from '../../data/airports.json';

export const createSearchIndex = () => {
    require('lunr-languages/lunr.stemmer.support')(lunr);
    require('lunr-languages/lunr.multi')(lunr);
    require('lunr-languages/lunr.no')(lunr);

    const lookup = {};
    const index = lunr((l) => {
        // @ts-ignore
        l.use(lunr.multiLanguage('en', 'no'));
        l.ref('icao');

        l.field('icao');
        l.field('iata');
        l.field('name');
        l.field('operator');
        l.field('keywords');
        l.field('tags');

        for (const airport of airports.airports) {
            l.add(airport);
            // @ts-ignore
            lookup[airport.icao] = airport;
        }
    });

    return { lookup, index };
};
