import { Route, RouteProps } from 'react-router-dom';
import UnhandledExceptionBoundary from './UnhandledExceptionBoundary';

export const RouteWithExceptionBoundary = (props: RouteProps): React.ReactElement => {
    return (
        <UnhandledExceptionBoundary>
            <Route {...props}>
                <UnhandledExceptionBoundary>{props.children}</UnhandledExceptionBoundary>
            </Route>
        </UnhandledExceptionBoundary>
    );
};
