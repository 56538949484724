import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { AirportInfo } from '../../models/airports';
import { createSearchIndex } from './createIndex';

const { lookup, index } = createSearchIndex();

const queryIsValid = (query?: string) => {
    return query && index;
};

const SearchResults = ({ query }: { query?: string }): React.ReactElement => {
    const history = useHistory();
    const { t } = useTranslation();
    const theme = useTheme();

    const [debouncedQuery] = useDebounce(query, 100);
    const results = useMemo(() => {
        const q = debouncedQuery?.replace(/[:+-^]$/, '');
        if (!queryIsValid(q)) {
            return [];
        }

        try {
            // @ts-ignore
            const r = index.search(q);
            // @ts-ignore
            return r.map(({ ref }) => lookup[ref]);
        } catch (_e) {
            return [];
        }
    }, [debouncedQuery]);

    return (
        <Grid component={Box} container spacing={theme.spacing(2)}>
            {results?.map((result: any) => {
                const airport = result as AirportInfo;
                return (
                    <Grid key={airport.icao} item sm={6} md={4}>
                        <Card>
                            <CardActionArea onClick={() => history.push(`/airport/${airport.icao}`)} sx={{ height: 200 }}>
                                <CardHeader title={airport.icao} sx={{ textAlign: 'center', paddingBottom: 0 }}></CardHeader>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Chip label={t(`airportType.${airport.type}`)} variant="outlined" sx={{ marginBottom: 1 }} />
                                    <Typography sx={{ overflow: 'clip', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{airport.name}</Typography>
                                    {airport?.tags?.map((tag) => (
                                        <Chip key={tag} label={tag} sx={{ marginTop: 1, marginRight: 1, marginLeft: 1, padding: 0, borderRadius: { xs: 2 } }} />
                                    ))}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default SearchResults;
