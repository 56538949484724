import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import { NavaidType } from '../../models/airport';

const NavaidIcon = ({ type }: { type: NavaidType }): React.ReactElement => {
    switch (type) {
        default:
            return <SettingsInputAntennaIcon fontSize="inherit" />;
    }
};

export default NavaidIcon;
