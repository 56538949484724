import Alert from '@material-ui/core/Alert';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import { default as React, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import airports from '../../data/airports.json';
import useTitle from '../../tools/useTitle';
import PageContainer from '../common/PageContainer';

const ImportStatus = ({ importDate }: { importDate: dayjs.Dayjs }): React.ReactElement => {
    const { t, i18n } = useTranslation('about');

    const statusColor = useMemo<'success' | 'warning' | 'error' | undefined>(() => {
        const diff = dayjs().diff(importDate, 'hour');
        if (diff < 24) {
            return 'success';
        }

        if (diff < 48) {
            return 'warning';
        }

        return 'error';
    }, [importDate]);

    return (
        <Chip
            sx={{ marginTop: 2 }}
            icon={<EventAvailableIcon />}
            label={t('dataSources.lastImported', {
                date: importDate.locale(i18n.language).format(i18n.language === 'nb' ? 'Do MMMM YYYY' : 'MMMM Do, YYYY'),
                ago: importDate.locale(i18n.language).fromNow()
            })}
            color={statusColor}
        />
    );
};

const DataSource = ({
    sourceName,
    sourceKey,
    importDate,
    url
}: {
    sourceName: string;
    sourceKey: 'ourAirports' | 'myPpr' | 'faa';
    importDate: dayjs.Dayjs;
    url: string;
}): React.ReactElement => {
    const { t } = useTranslation('about');

    return (
        <ListItem alignItems="flex-start" divider>
            <ListItemText
                primary={
                    <>
                        {sourceName}{' '}
                        <Link href={url} target="_blank" rel="noreferrer">
                            <OpenInNewIcon fontSize="small" />
                        </Link>
                    </>
                }
                secondary={t(`dataSources.${sourceKey}.intro`)}
            />
            <Hidden mdDown>
                <ImportStatus importDate={importDate} />
            </Hidden>
        </ListItem>
    );
};

const About = (): React.ReactElement => {
    const { t } = useTranslation('about');

    useTitle(t('title'));

    const ourAirportsLastGenerated = dayjs(airports.sources.ourAirports);
    const myPprLastGenerated = dayjs(airports.sources.myPpr);
    const lastGenerated = dayjs(airports.generated);

    return (
        <PageContainer>
            <Box mb={3}>
                <Typography variant="h3" component="h1">
                    {t('title')}
                </Typography>
                <Typography variant="body1">{t('intro')}</Typography>
                <Alert severity="warning" sx={{ marginTop: 4 }}>
                    {t('warning')}
                </Alert>
            </Box>
            <Box>
                <Typography variant="h4" component="h2">
                    {t('dataSources.title')}
                </Typography>
                <List>
                    <DataSource
                        sourceName="OurAirports.com"
                        sourceKey="ourAirports"
                        importDate={ourAirportsLastGenerated}
                        url="https://ourairports.com/data/"
                    />
                    <DataSource sourceName="MyPPR.no" sourceKey="myPpr" importDate={myPprLastGenerated} url="https://www.myppr.no/" />
                    <DataSource sourceName="FAA" sourceKey="faa" importDate={lastGenerated} url="https://notams.aim.faa.gov/" />
                </List>
            </Box>
        </PageContainer>
    );
};

export default About;
