import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import { default as React } from 'react';

const PageContainer = (props: { children: React.ReactChild | React.ReactFragment | React.ReactPortal; fullSize?: boolean }): React.ReactElement => {
    const theme = useTheme();

    return (
        <Container
            maxWidth={props.fullSize ? false : undefined}
            sx={{ paddingTop: theme.spacing(props.fullSize ? 2 : 10), paddingBottom: theme.spacing(props.fullSize ? 2 : 10) }}>
            {props.children}
        </Container>
    );
};

export default PageContainer;
