import Container from '@material-ui/core/Container';
import { createTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/core/styles/ThemeProvider';
import React from 'react';
import { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Routes from './Routes';

const Layout = (): React.ReactElement => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#3f51b5'
            },
            secondary: {
                main: '#f50057'
            }
        }
    });

    const [headerHeight, setHeaderHeight] = useState(0);

    return (
        <ThemeProvider theme={theme}>
            <Header onHeightUpdate={setHeaderHeight} />
            <Container
                component="main"
                sx={{
                    flex: '1 0 auto',
                    marginTop: `${headerHeight}px`,
                    marginBottom: 0,
                    padding: { xs: 0 },
                    marginLeft: 0,
                    marginRight: 0
                }}
                maxWidth={false}>
                <Routes />
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default Layout;
