import React from 'react';
import { DistanceInfo } from '../../models/distanceInfo';

export const DistanceText = ({ distance = undefined }: { distance?: DistanceInfo }): React.ReactElement => {
    if (!distance) {
        return <></>;
    }

    return (
        <>
            {distance.feet} ft<br />{distance.meter} m
        </>
    );
};
