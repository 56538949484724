import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';

const RenderHtml = ({ html }: { html?: string }): React.ReactElement => {
    const purified = useMemo(() => DOMPurify.sanitize(html ?? ''), [html]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return <>{<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(purified) }} />}</>;
};

export default RenderHtml;
