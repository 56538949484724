import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

const fullDateFormatNb = 'dddd DD. MMM  HH:mm';
const fullDateFormatEn = 'dddd, MMMM Do HH:mm';
const timeFormat = 'HH:mm';

export const TimePeriod = ({
    from,
    to,
    isNotam = false,
    style = undefined
}: {
    from: string;
    to: string;
    isNotam: boolean;
    style: any;
}): React.ReactElement => {
    const { t, i18n } = useTranslation('common');

    const fromDate = isNotam ? dayjs(from, 'MM/DD/YYYY HHmm') : dayjs(from);
    const toDate = isNotam ? dayjs(to, 'MM/DD/YYYY HHmm') : dayjs(to);
    const permanent = to === 'PERM';

    if (fromDate.isSame(toDate, 'day')) {
        return (
            <span style={{ ...style }}>
                {fromDate.locale(i18n.language).format(i18n.language === 'nb' ? fullDateFormatNb : fullDateFormatEn)} -{' '}
                {toDate.locale(i18n.language).format(timeFormat)}
            </span>
        );
    }

    return (
        <span style={{ ...style }}>
            {fromDate.locale(i18n.language).format(i18n.language === 'nb' ? fullDateFormatNb : fullDateFormatEn)} -{' '}
            {permanent ? t('time.permanent') : toDate.locale(i18n.language).format(i18n.language === 'nb' ? fullDateFormatNb : fullDateFormatEn)}
        </span>
    );
};
